import React from 'react';

import PageWrap from 'components/PageWrap';
import { Section } from 'components/Section';
import { PagePicTitle } from 'components/PagePicTitle';

import { FeaturePoint } from 'components/FeaturePoint';
import CallsToAction from 'components/CallsToAction';
import { Separator } from 'components/Separator';
import { SEO } from 'components/Helmets';

import ContactIcon from 'media/shared/modIcons/Contact.inline.svg';

import missionControlImg from 'media/Screenshots/Contact/ContactMissionControl.png';
import contactRelationsImg from 'media/Screenshots/Contact/ContactRelations.png';
import searchAndDetailsImg from 'media/Screenshots/Contact/ContactSearchAndDetails.png';
import outlookAddIn from 'media/Screenshots/Contact/OutlookAddIn.png';

export default function ModForms({data}){
    const CTATypes = ['SignUp', 'Demo'];
    const pagePath = "/modules/forms";

    return (
        <PageWrap noSEO={true} path={pagePath}>
            <SEO
                title="The Contact Module - Mashoom"
                description="A simple yet power CRM solution"
                previewImgKey="contact"
                path={pagePath}
            />
            <PagePicTitle
                imageCmp={ContactIcon}
                imageAlt="Contact module icon"
            >
                <h1>The Contact Module</h1>
                <p>CRM is often made so complicated, it doesn't have to be. Store and organize your contacts like you would your address book, and use our simple workflow to manage lead chasing.</p>
            </PagePicTitle>
            <FeaturePoint
                title="Why make CRM so complicated?"
                image={searchAndDetailsImg}
                pageInd="1"
            >
                <p>We all own an address book, and it's very effective way of keeping track of who we know and how to contact them. So why make your CRM package more complicated?</p>
                <p className="paraSpaceTop">Welcome to simple, Mashoom's Contact module is a simple list of contacts and companies. Standard fields like name and address are provided, but any number of custom fields can be setup to personalise the data you want to collect. This data can then be searched for to produce lists of contacts for calling etc.</p>
            </FeaturePoint>
            <Separator />
            <FeaturePoint
                title="Log What You've Done, And What's Next"
                image={missionControlImg}
                pageInd="2"
            >
                <p>Contacts can have activity and tasks created for them, logging any interactions and scheduling future work like call-backs.</p>
                <p className="paraSpaceTop">This feeds into Mission Control; a list of all the tasks you that have been assigned for you, with a simple interface to complete each one and schedule the next.</p>
            </FeaturePoint>
            <Separator />
            <FeaturePoint
                title="Relationship Matter"
                image={contactRelationsImg}
                pageInd="3"
            >
                <p>Relations can be created between contacts, merging their timeline, for instance showing all the sales calls made to different people within a company.</p>
                <p className="paraSpaceTop">This allows you to properly store all the interactions with each person inside of an organization. This is simply useful to separate all their contact details, but also allows interactions to feel more personal as you know what was said to each person individually.</p>
            </FeaturePoint>
            <Separator />
            <FeaturePoint
                title="Make Use Of Your Emails"
                image={outlookAddIn}
                pageInd="4"
            >
                <p>Mashoom's Outlook Add-in allows a emails and their contact information to be uploaded to Mashoom</p>
                <p className="paraSpaceTop">Most times this can be done with two clicks of a button. However, emails are notoriously tricky to harvest information from and sometimes you don't need or want the whole message. Our add-in lets you review the data before it gets posted, keeping your CRM clean.</p>
            </FeaturePoint>
            <Section id="FeatureCTA">
                <div id="FeatureCTACont">
                    <h2>Like what your hear?</h2>
                    <CallsToAction types={CTATypes}></CallsToAction>
                </div>
            </Section>
        </PageWrap>
    );
}